<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Restrict Form</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleRestrictForm" v-slot="{ errors }">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Restrict Forms</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.Forms }">
                                    <Field autocomplete="off" name="Forms" v-model="form.form_id" rules="required">
                                        <multiselect
                                            v-model="form.form_id"
                                            label="title"
                                            value-prop="id"
                                            :searchable="true"
                                            :options="forms"
                                            placeholder="Select forms"
                                            mode="tags"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="Forms" class="text-danger" />
                            </div>
                        </div>
                        <div v-show="form.form_id.length">
                            <schedule-component :schedule-data="form" title="form restrict" :close-modal="closeModal" />
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="formLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="formLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="formLoader"></i>{{ formLoader ? ' Restrict' : 'Restrict' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex';

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    export default {
        name: 'Restrict Form',

        data () {
            return {
                form: {
                    contacts: '',
                    form_id: [],
                    action: '/contacts/restrict-form',
                    sending_method: 1,
                },
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val == true) {
                    vm.form.contacts = vm.contacts;
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                if (vm.forms.length == 0) {
                    vm.getAllForms();
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            ScheduleComponent
        },

        computed: {
            ...mapState({
                forms: state => state.formModule.allForms,
                formLoader: state => state.formModule.formComponentLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getAllForms: 'formModule/getAllForms',
                restrictForm: 'formModule/restrictForm',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                              contacts: '',
                              form_id: [],
                              action: '/contacts/restrict-form',
                              sending_method: 1,
                          };
            },

            handleRestrictForm (params, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                vm.restrictForm(vm.form);
            },
        },
    }
</script>
